<template>
    <app-wrapper>
        <template v-slot:child>
            <InventoryHeader :type="'STOCK_TRANSFER'" :title="'Stock Transfer Requests'" />
            <template v-if="$store.state.Inventory?.loading || !stockTransfers">
                <Spinner />
            </template>

            <!-- <div class="w-90 center mb3">
                <div
                    class="pv3 flex flex-wrap justify-between items-center"
                    style="border-top: 1px solid #e2e8ee; border-bottom: 1px solid #e2e8ee"
                >
                    <div class="flex flex-wrap items-start w-60-l w-100 pt0-l pt3">
                        <div class="filter card-box1x ml3">
                            <div class="tc flex items-center ph3">
                                <img :src="require('@/assets/images/Reload.svg')" alt="" />
                            </div>
                        </div>
                    </div>

                    <div class="w-40-l w-100">
                        <SearchInput placeholder="Search request..." v-model="state.searchVal" @update:modelValue="search" />
                    </div>
                </div>
            </div> -->

            <div class="scrollmenu w-90 center" style="height: 80%">
                <table v-if="stockTransfers?.data?.length" class="w-100 tl mt3" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="pb3 pr3 box-border-bottom tl">
                                <span>Reference</span>
                            </th>
                            <th class="pb3 pr3 box-border-bottom tl">
                                <span>Source Business</span>
                            </th>
                            <th class="pb3 pr3 box-border-bottom tl">
                                <span>Destination Business</span>
                            </th>
                            <th class="pb3 pr3 box-border-bottom tl">
                                <span>No. of Items</span>
                            </th>
                            <th class="pb3 pr3 box-border-bottom tl">
                                <span>Inventory Value</span>
                            </th>
                            <th class="pb3 box-border-bottom tl pr2">
                                Date
                            </th>
                            <th class="pb3 box-border-bottom tl">Status</th>
                            <th class="pb3 box-border-bottom tc">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="stockTransfer in stockTransfers?.data" v-bind:key="stockTransfer._id" class="font-w1">
                            <td class="pv3 pr4 box-border-bottom tl b">
                                <router-link :to="{ name: 'StockTransferView', params: { id: stockTransfer._id } }">
                                    {{ stockTransfer.reference }}
                                </router-link>
                            </td>
                            <td class="pv3 pr4 box-border-bottom tl">{{ stockTransfer.sourceBusiness.name }}</td>
                            <td class="pv3 pr4 box-border-bottom tl">{{ stockTransfer.destinationBusiness.name }}</td>
                            <td class="pv3 pr4 box-border-bottom tc">{{ stockTransfer?.totalItems || getTotalItems(stockTransfer?.products || []) }}</td>
                            <td class="pv3 pr4 box-border-bottom tr">{{ formatAmount(stockTransfer?.totalValue || getTotalValue(stockTransfer?.products || []), $store.state.Settings?.currency) }}</td>
                            <td class="pv3 pr4 box-border-bottom tl">{{ stockTransfer.date }}</td>
                            <td class="pv3 pr4 box-border-bottom tl">{{ stockTransfer?.status === 'pending' ? 'awaiting transfer' : (stockTransfer?.status || 'awaiting transfer') }}</td>
                            <td class=" pv3 pr4 box-border-bottom tc">
                                <div class="dropdown tc" style="position:relative;">
                                    <a
                                        class="dropdown-togglex "
                                        href="#"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="fa-2x" />
                                    </a>

                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(61)">
                                            <router-link
                                                :to="{ name: 'StockTransferView', params: { id: stockTransfer._id } }"
                                            >
                                                View
                                            </router-link>
                                        </li>
                                        <li v-if="stockTransfer?.status === 'draft' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(63))">
                                            <router-link
                                                :to="{ name: 'TransferStock', query: { id: stockTransfer._id } }"
                                            >
                                                Edit
                                            </router-link>
                                        </li>
                                        <li v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(62)">
                                            <a
                                                href="#"
                                                class="dropdown-itemx"
                                                @click="duplicateStockTransfer(stockTransfer._id)"
                                            >
                                                Duplicate
                                            </a>
                                        </li>
                                        <li v-if="stockTransfer?.status === 'pending' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(65))">
                                            <a
                                                href="#"
                                                class="dropdown-itemx"
                                                @click="actOnStockTransfer(stockTransfer._id, 'approved')"
                                            >
                                                Approve
                                            </a>
                                        </li>
                                        <li v-if="stockTransfer?.status === 'pending' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(65))">
                                            <a
                                                href="#"
                                                class="dropdown-itemx"
                                                @click="actOnStockTransfer(stockTransfer._id, 'rejected')"
                                            >
                                                Reject
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <TableEmptyState v-else :data-array="stockTransfers?.data" />
            </div>

            <!-- pagination -->
            <Pagination :pages="stockTransfers?.pagination" route-name="StockTransfers" :onNavigate="onNavigate" />
            <!-- pagination -->
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, reactive, computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import AppWrapper from '@/layout/AppWrapper'
import InventoryHeader from '@/views/Inventory/widgets/InventoryHeader'
import Pagination from '@/components/Pagination'
import CreateEntity from '@/components/CreateEntity'
// import AlgoliaAutoComplete from '@/components/AlgoliaAutoComplete'
import EntityBulkUploadModal from '@/views/Entities/widgets/EntityBulkUploadModal'
import { formatAmount, formatDate, formatDateFilter, sortByNumericData, sortAlphaNumericData } from '@/utils/lib'
import Spinner from '@/components/Spinner'
import TableEmptyState from '@/components/TableEmptyState'
import SearchInput from 'vue-search-input'

export default {
    name: 'Entities',
    components: {
        AppWrapper,
        InventoryHeader,
        Pagination,
        CreateEntity,
        Spinner,
        TableEmptyState,
        // AlgoliaAutoComplete,
        SearchInput,
        EntityBulkUploadModal
    },

    data() {
        return {
            term: ''
        }
    },
    data: () => ({
        isOpen: true
    }),

    setup() {
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const stockTransfers = computed(() => store?.state.Inventory.stockTransfers)
        const entities = computed(() => store?.state.Entity.entities)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const orgData = computed(() => store?.state?.Auth?.userData)
        const getName = ref('')

        const onNavigate = query => {
            store.dispatch('Entity/getEntities', query)
        }

        watch(
            () => entities.value,
            (prevValue, currValue) => {
                if (prevValue !== currValue) {
                    // if (prevValue || currValue) {
                    state.entities = prevValue || currValue
                    // }
                }
            }
        )

        const refreshHandler = () => {
            state.isEntityRoleOpen = false
            store.dispatch('Entity/getEntities')
        }

        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const day = new Date().getDate()
        const lastYear = year - 1

        const defaultStartDate_1 = new Date(year, 0, 1)

        const state = reactive({
            loading: true,
            startDate1: defaultStartDate_1,
            entities: entities.value,
            isEntityRoleOpen: false,
            searchVal: ''
        })

        const toggleTooltip = ref(false)
        const closeModal = () => {
            toggleTooltip.value = false
        }

        const getTotalItems = (products) => { 
            const totalItems = products.reduce((prevVal, currValue) => {
                prevVal += parseFloat(currValue.quantity)
                return prevVal
            }, 0)

            return totalItems
        }

        const getTotalValue = (products) => { 
            const totalValue  = products.reduce((prevVal, currValue) => {
                prevVal += parseFloat(currValue.price) * parseFloat(currValue.quantity)
                return prevVal
            }, 0)

            return totalValue
        }

        const actOnStockTransfer = (id, status) => {
            const title = status === 'approved' ? 'Approve' : 'Reject'
            const content = status === 'approved' ? 'approve' : 'reject'
            Swal.fire({
                title: `${title} Stock Transfer`,
                text: `Are you sure you want to ${content} this stock transfer?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Yes, ${title}!`,
                denyButtonText: `No, Cancel!`
            }).then(result => {
                if (result.isConfirmed) {
                    store.dispatch('Inventory/actOnStockTransfer', { id, status })
                        .finally(() => getStockTransfers())
                }
            })
        }

        const duplicateStockTransfer = async (id) => {
            Swal.fire({
                    title: `Confirm Action`,
                    text: `Are you sure you want to duplicate this stock transfer?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, Continue!',
                    denyButtonText: `No, Cancel!`
                }).then(async result => {
                    if (result.isConfirmed) {
                        await store.dispatch('Inventory/duplicateStockTransfer', { id }).then( resp => {
                                if (resp.status) {
                                    router.push({
                                        name: 'TransferStock',
                                        query: { id: resp.data }
                                    })
                                }
                            })
                                    
                    }
                })
        }

        const getStockTransfers = async () => {
            const query = route.query.limit && route.query.page ? { limit: route.query.limit, page: route.query.page } : null
            state.startDate1 = formatDateFilter(state.startDate1)
            await store.dispatch('Inventory/stockTransfers', { query })
        }

        onMounted(async () => {
            window.addEventListener('click', event => {
                const modalIcon = document.querySelector('.clickModalIcon')

                if (!(event.target.closest('.clickModalIcon') || event.target === modalIcon) && toggleTooltip.value === true) {
                    closeModal()
                }
            })

            await getStockTransfers()
        })

        const search = () => {
            const query = {
                limit: route.query.limit || 25,
                page: route.query.page || 1,
                filterType: 'search',
                q: state.searchVal
            }

            state.startDate1 = formatDateFilter(state.startDate1)
            store.dispatch('Entity/getEntities', query)
        }

        return {
            entities,
            stockTransfers,
            formatAmount,
            formatDate,
            onNavigate,
            refreshHandler,
            state,
            rolePermissions,
            role,
            orgData,
            getName,
            search,
            toggleTooltip,
            closeModal,
            actOnStockTransfer,
            duplicateStockTransfer,
            getTotalItems,
            getTotalValue,
        }
    }
}
</script>

<style scoped>
/* Entity filter dropdown */
.entity-filter-dropdown {
    cursor: pointer;
    position: relative;
    /* left: 50%; */
    /* top: 50%; */
    /* transform: translate(-50%, -70px); */
    /* min-width: 350px; */
    /* height: 40px; */
    /* z-index: 1; */
}

/* Selected */
.entity-filter-btn {
    position: relative;
    /* z-index: 2; */
    /* display: block; */
    width: 100%;
    height: 30px;
    padding: 0 10px;
    /* background: #054641; */
    /* border-radius: 10px; */
    font: 1.25rem/40px 'Ubuntu', Helvetica, Arial, sans-serif;
    /* text-shadow: 2px 2px 0px #000; */
    /* color: #fff;   */
}

/* Arrow */
.entity-filter-btn:after {
    opacity: 0.5;
    display: inline-block;
    margin-left: 10px;
    content: '▼';
}

/* Hover state */
.entity-filter-btn:hover:after {
    opacity: 1;
}

/* Options wrapper (toggled by isOpen) */
.options {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1;
    width: 100%;
    margin-top: 3px;
    background: #fff;
    /* border-radius: 10px; */
    border: 1px solid rgba(0, 0, 0, 0.25);
    text-align: left !important;
}

/* Option */
.option {
    padding: 5px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

/* Hover state */
.option:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* Reset last child for a nice layout */
.option:last-child {
    border-bottom: none;
}

/* Transition */
.fade-enter-active,
.fade-leave-active {
    transition: all 0.25s ease-out;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
    transform: translateY(-30px);
}
</style>
